import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/style.css'

Vue.config.productionTip = false
Vue.use(ElementUI);

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')
new Vue({
  el: '#app',
    router,
	store,
  render: h => h(App)
});