import request from '@/utils/request'

// 获取菜单列表
export function login(data) {
	console.log(request)
  return request({
    url: '/login',
    method: 'post',
	data:data
  })
}
