<template>
	<div class="box">
		<div class="title">
			晋州零工市场后台管理
		</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
			<el-form-item prop="username">
				<el-input prefix-icon="el-icon-user" placeholder="请输入用户名" v-model="ruleForm.username"></el-input>
			</el-form-item>
			<el-form-item prop="password">
				<el-input prefix-icon="el-icon-lock" placeholder="请输入密码" type="password" show-password v-model="ruleForm.password"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">登陆</el-button>
				<el-button @click="resetForm('ruleForm')">重置</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import {
		login
	} from '@/api/login'
	export default {
		name: 'HomeView',
		components: {},
		data() {
			return {
				ruleForm: {
					username: "",
					password: "",
				},
				rules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'change'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'change'
					}],
				}
			};
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						login(this.ruleForm).then(res => {
							if (res.code != 200) {
								return this.$message.error(res.message)
							}
							if(res.data.isAdmin!=1){
								return this.$message.error('该账号不是管理员')
							}
							this.$message.success(res.message)
							window.sessionStorage.setItem('token', res.data.token)
							window.sessionStorage.setItem('userId', res.data.id)
							this.$router.push('/home')
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box {
		max-width: 300px;
		margin: auto;
		text-align: center;
		position: absolute;
		left: 0;
		right: 0;
		top: 30%;

		.title {
			text-align: center;
			margin-bottom: 24px;
			font-weight: bold;
			font-size: 18px;
		}

		.demo-ruleForm {}
	}
</style>