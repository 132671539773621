import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Welcome from '../views/Welcome.vue'
import positionList from '../views/position/List.vue'
import userList from '../views/user/userList.vue'
import adminList from '../views/user/adminList.vue'
import commodityList from '../views/commodity/commodityList.vue'





Vue.use(VueRouter)

const routes = [{
		path: '/',
		component: Login
	},
	{
		path: '/login',
		component: Login
	},
	{
		path: '/home',
		component: Home,
		redirect: '/welcome',
		children: [{
				path: '/welcome',
				component: Welcome
			},
			{
				path: '/userList',
				component: userList
			},
			{
				path: '/adminList',
				component: adminList
			},
			{
				path: '/positionList',
				component: positionList
			},
			{
				path: '/commodityList',
				component: commodityList
			},
		]
	}
]

const router = new VueRouter({
	routes
})

export default router