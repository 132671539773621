<template>
	<div>
		<!-- 搜索区域 -->
		<el-card class="box-card">
			<el-form :model="queryParam" :rules="rules" ref="form" label-width="80px">
				<el-row :gutter="12">
					<el-col :span="5">
						<el-form-item label="关键字" prop="keyword">
							<el-input v-model="queryParam.keyword" placeholder="关键字"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="岗位类型" prop="class">
							<el-select v-model="queryParam.class" placeholder="请选择岗位类型">
								<el-option label="不限" value=""></el-option>
								<el-option :label="item.title" :value="item.title"
									v-for="(item,index) in positionTypeList" :key="index"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="结算方式" prop="method">
							<el-select v-model="queryParam.method" placeholder="请选择结算方式">
								<el-option label="不限" value=""></el-option>
								<el-option label="日结" value="日结"></el-option>
								<el-option label="周结" value="周结"></el-option>
								<el-option label="月结" value="月结"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item>
							<el-button type="primary" @click="submitForm">查询</el-button>
							<el-button @click="resetForm">重置</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<!-- 操作区 -->
		<el-card class="box-card" style="text-align: right;">
			<el-button type="primary" @click="editRow({})">添加</el-button>
		</el-card>
		<!-- 内容区 -->
		<el-card class="box-card">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="position" label="岗位名称">
				</el-table-column>
				<el-table-column prop="class" label="岗位类型">
				</el-table-column>
				<el-table-column prop="method" label="结算方式">
				</el-table-column>
				<el-table-column prop="area" label="工作地区">
				</el-table-column>
				<el-table-column prop="address" label="详细地址">
				</el-table-column>
				<el-table-column prop="nature" label="招工性质">
				</el-table-column>
				<el-table-column prop="number" label="招聘人数">
				</el-table-column>
				<el-table-column prop="name" label="发布人">
				</el-table-column>
				<el-table-column prop="phone" label="联系方式">
				</el-table-column>
				<el-table-column prop="toppingDay" label="置顶套餐/天">
				</el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<el-tag type="warning" v-if="scope.row.status==0">待审核</el-tag>
						<el-tag type="success" v-if="scope.row.status==1">审核通过</el-tag>
						<el-tag type="danger" v-if="scope.row.status==2">审核驳回</el-tag>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="240">
					<template slot-scope="scope">
						<el-button @click="editRow(scope.row)" size="small">编辑</el-button>
						<el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteRow(scope.row)">
							<el-button slot="reference" size="small">删除</el-button>
						</el-popconfirm>
						<el-button @click="examineRow(scope.row)" size="small">
							<span v-if="scope.row.status==1">审核驳回</span>
							<span v-else>审核通过</span>
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="paginationBox">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="queryParam.page" :page-sizes="[10, 20, 30, 40]" :page-size="queryParam.size"
					layout="total, sizes, prev, pager, next, jumper" :total="queryParam.total">
				</el-pagination>
			</div>
		</el-card>
		
		<editdPositionDialogVisible :editForm="editForm" :visible.sync="editdialogVisible" @confirm="confirmeditForm" @close="closeeditForm"></editdPositionDialogVisible>
	</div>
</template>

<script>
	import {
		positionType,
		positionList,
		positionExamine,
		positionDelete
	} from '@/api/position'
	import editdPositionDialogVisible from '@/components/editdPositionDialogVisible/index.vue'
	export default {
		components:{
			editdPositionDialogVisible
		},
		data() {
			return {
				queryParam: {
					page: 1,
					size: 10,
					keyword: '',
					class: '',
					method: ''
				},
				rules: {
					keyword: [{
						required: false,
						trigger: 'change'
					}],
					class: [{
						required: false,
						trigger: 'change'
					}],
					method: [{
						required: false,
						trigger: 'change'
					}],
				},
				positionTypeList: [],
				tableData: [],
				editForm:{},
				editdialogVisible:false
			}
		},
		mounted() {
			this.getpositionType()
			this.getpositionList()
		},
		methods: {
			confirmeditForm(e){
				console.log(e)
				this.getpositionList()
			},
			closeeditForm(e){
				console.log(e)
			},
			handleSizeChange(val) {
				this.queryParam.size = val
				this.getpositionList()
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.queryParam.page = val
				this.getpositionList()
				console.log(`当前页: ${val}`);
			},
			editRow(item) {
				this.editForm = item
				this.editdialogVisible = true
			},
			deleteRow(item) {
				var data = {
					id:item.id,
					isDelete:1
				}
				positionDelete(data).then(res=>{
					var _this = this
					this.$message({
						message: res.message,
						type: 'success',
						onClose(e) {
							_this.getpositionList()
						}
					})
				})
			},
			examineRow(item) {
				var data = {
					id: item.id
				}
				if (item.status == 1) {
					data.status = 2
					console.log('审核驳回')
				} else {
					data.status = 1
					console.log('审核通过')
				}
				positionExamine(data).then(res => {
					var _this = this
					this.$message({
						message: res.message,
						type: 'success',
						onClose(e) {
							_this.getpositionList()
						}
					})

					console.log(res)
				})
			},
			getpositionList() {
				positionList(this.queryParam).then(res => {
					this.tableData = res.data.data
					this.queryParam.page = res.data.page
					this.queryParam.total = res.data.total
				})
			},
			getpositionType() {
				positionType().then(res => {
					this.positionTypeList = res.data
				})
			},
			submitForm() {
				this.getpositionList()
			},
			resetForm() {
				this.$refs.form.resetFields();
			},
		}
	}
</script>

<style lang="scss" scoped>
	.box-card {
		margin-bottom: 12px;
	}

	.bg-purple {
		background: #d3dce6;
	}

	.el-form-item {
		margin-bottom: 0;
	}
	.paginationBox{
		margin-top: 24px;
		text-align: center;
	}
</style>