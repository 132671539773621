<template>
	<div>
		<!-- 搜索区域 -->
		<el-card class="box-card">
			<el-form :model="queryParam" :rules="rules" ref="form" label-width="80px">
				<el-row :gutter="12">
					<el-col :span="5">
						<el-form-item label="用户名" prop="username">
							<el-input v-model="queryParam.username" placeholder="用户名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="昵称" prop="nickname">
							<el-input v-model="queryParam.nickname" placeholder="昵称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="真实姓名" prop="fullName">
							<el-input v-model="queryParam.fullName" placeholder="真实姓名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item>
							<el-button type="primary" @click="submitForm">查询</el-button>
							<el-button @click="resetForm">重置</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<!-- 操作区 -->
		<el-card class="box-card" style="text-align: right;">
			<el-button type="primary" @click="editRow({})">添加</el-button>
		</el-card>
		<!-- 内容区 -->
		<el-card class="box-card">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="username" label="用户名">
				</el-table-column>
				<el-table-column prop="fullName" label="真实姓名">
				</el-table-column>
				<el-table-column prop="c_time" label="创建时间">
				</el-table-column>
				<el-table-column prop="phone" label="手机号">
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="240">
					<template slot-scope="scope">
						<el-button @click="editRow(scope.row)" size="small">编辑</el-button>
						<el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteRow(scope.row)">
							<el-button slot="reference" size="small">删除</el-button>
						</el-popconfirm>
					</template>
				</el-table-column>
			</el-table>
			<div class="paginationBox">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="queryParam.page" :page-sizes="[10, 20, 30, 40]" :page-size="queryParam.size"
					layout="total, sizes, prev, pager, next, jumper" :total="queryParam.total">
				</el-pagination>
			</div>
		</el-card>
		<editdAdminDialogVisible :editForm="editForm" :visible.sync="editdialogVisible" @confirm="confirmeditForm"
			@close="closeeditForm"></editdAdminDialogVisible>
	</div>
</template>

<script>
	import {
		adminList,
		deleteUser
	} from '@/api/user'
	import editdAdminDialogVisible from '@/components/editdAdminDialogVisible/index.vue'
	export default {
		components: {
			editdAdminDialogVisible
		},
		data() {
			return {
				queryParam: {
					page: 1,
					size: 10,
				},
				rules: {
					keyword: [{
						required: false,
						trigger: 'change'
					}],
					class: [{
						required: false,
						trigger: 'change'
					}],
					method: [{
						required: false,
						trigger: 'change'
					}],
				},
				tableData: [],
				editForm: {},
				editdialogVisible: false
			}
		},
		mounted() {
			this.getadminList()
		},
		methods: {
			confirmeditForm(e) {
				console.log(e)
				this.getadminList()
			},
			closeeditForm(e) {
				console.log(e)
			},
			handleSizeChange(val) {
				this.queryParam.size = val
				this.getadminList()
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.queryParam.page = val
				this.getadminList()
				console.log(`当前页: ${val}`);
			},
			editRow(item) {
				this.editForm = item
				this.editdialogVisible = true
			},
			deleteRow(item) {
				if (item.id == window.sessionStorage.getItem('userId')) {
					return this.$message.error('不能删除自己账号')
				}
				var data = {
					id: item.id,
				}
				deleteUser(data).then(res => {
					var _this = this
					this.$message({
						message: res.message,
						type: 'success',
						onClose(e) {
							_this.getadminList()
						}
					})
				})
			},
			getadminList() {
				adminList(this.queryParam).then(res => {
					this.tableData = res.data.data
					this.queryParam.page = res.data.page
					this.queryParam.total = res.data.total
				})
			},
			submitForm() {
				this.getadminList()
			},
			resetForm() {
				this.$refs.form.resetFields();
			},
		}
	}
</script>

<style lang="scss" scoped>
	.box-card {
		margin-bottom: 12px;
	}

	.bg-purple {
		background: #d3dce6;
	}

	.el-form-item {
		margin-bottom: 0;
	}

	.paginationBox {
		margin-top: 24px;
		text-align: center;
	}
</style>