import axios from "axios";
import { MessageBox, Message } from "element-ui";
// import store from "@/store";
import router from "@/router";

// 创建 axios 实例
const service = axios.create({
  baseURL: 'https://lgsc.chaocehr.cn/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
});

// request 拦截器
service.interceptors.request.use(
  (config) => {
      config.headers['Authorization'] = window.sessionStorage.getItem('token') // 让每个请求携带自定义token 请根据实际情况自行修改
    return config;
  },
  (error) => {
    // do something with request error
    console.log('error',error); // for debug
    return Promise.reject(error);
  }
);

let isRefreshing = false;
// response 拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
	console.log(response)
    const res = response.data;

    //将响应文件名信息直接添加挂载rea.data
    if (response.headers["content-disposition"]) {
      res.contentDisposition = response.headers["content-disposition"];
    }
    if (res.type === "application/octet-stream" || res.success) {
      return res;
    }

    if (res.type === "text/xml") {
      return res;
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
		
      Message({
        message: res.message || res.msg || "Error",
        type: "error",
        duration: 5 * 1000,
      });

      return Promise.reject(new Error(res.message || res.msg || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    // 当等于401的时候 表示 后端告诉我token超时了
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        MessageBox.confirm("很抱歉，登录已过期，请重新登录", {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
				window.sessionStorage.removeItem("token");
				window.sessionStorage.removeItem("userId");
				router.push("/login");
          })
          .catch(() => {
			  window.sessionStorage.removeItem("token");
			  window.sessionStorage.removeItem("userId");
			  router.push("/login");
          });
      }
    } else {
      Message.error(error.message || error.msg); // 提示错误信息
    }
	console.log(error)
    return Promise.reject(error);
  }
);

export default service;
