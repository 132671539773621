<template>
	<div>
		<el-dialog title="提示" :visible.sync="visible" width="40%">
			<el-form :model="editForm" :rules="rules" ref="editForm" label-width="100px" class="demo-editForm">
				<div style="max-height: 500px;overflow-x: auto;">
				<el-form-item label="职位名称" prop="position">
					<el-input v-model="editForm.position"></el-input>
				</el-form-item>
				<el-form-item label="招工性质" prop="nature">
					 <el-radio-group v-model="editForm.nature">
					      <el-radio label="公司"></el-radio>
					      <el-radio label="个人"></el-radio>
					    </el-radio-group>
				</el-form-item>
				<el-form-item label="职位分类" prop="class">
					<el-select v-model="editForm.class" placeholder="请选择活动区域">
						<el-option :label="item.title" :value="item.title" v-for="(item,index) in zwflList" :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="薪资待遇" prop="salary">
					<el-select v-model="editForm.salary" placeholder="请选择活动区域">
						<el-option :label="item.title" :value="item.title" v-for="(item,index) in xzdyList" :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="结算形式" prop="method">
					<el-select v-model="editForm.method" placeholder="请选择活动区域">
						<el-option :label="item.title" :value="item.title" v-for="(item,index) in jsxsList" :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="置顶时长" prop="toppingDay">
					<el-select v-model="editForm.toppingDay" placeholder="请选择活动区域">
						<el-option :label="item.title" :value="item.day" v-for="(item,index) in ToppingData" :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="招聘人数" prop="number">
					  <el-input-number v-model="editForm.number" :min="1" label="描述文字"></el-input-number>
				</el-form-item>
				<el-form-item label="工作描述" prop="des">
					<el-input type="textarea" v-model="editForm.des"></el-input>
				</el-form-item>
				<el-form-item label="联系人" prop="name">
					<el-input v-model="editForm.name"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="phone">
					<el-input v-model="editForm.phone"></el-input>
				</el-form-item>
				<el-form-item label="工作地区" prop="area">
					<el-input v-model="editForm.area"></el-input>
				</el-form-item>
				<el-form-item label="详细地址" prop="address">
					<el-input v-model="editForm.address"></el-input>
				</el-form-item>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" @click="confirm" :loading="loading">提 交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	
	import {
		ToppingList,
		positionType,
		positionAdd,
		positionEdit
	} from '@/api/position'
	export default {
		name: 'editdPositionDialogVisible',
		props: {
			editForm: Object,
			visible: Boolean
		},
		data() {
			return {
				rules: {
					position: [{
							required: true,
							message: '请输入职位名称',
							trigger: 'blur'
						}
					],
					nature: [{
							required: true,
							message: '请选择招工性质',
							trigger: 'blur'
						}
					],
					class: [{
							required: true,
							message: '请选择职位分类',
							trigger: 'blur'
						}
					],
					salary: [{
							required: true,
							message: '请选择薪资待遇',
							trigger: 'blur'
						}
					],
					method: [{
							required: true,
							message: '请选择结算形式',
							trigger: 'blur'
						}
					],
					toppingDay: [{
							required: true,
							message: '请选择置顶时长',
							trigger: 'blur'
						}
					],
					number: [{
							required: true,
							message: '请输入招聘人数',
							trigger: 'blur'
						}
					],
					des: [{
							required: false,
							message: '请输入工作描述',
							trigger: 'blur'
						}
					],
					name: [{
							required: true,
							message: '请输入联系人',
							trigger: 'blur'
						}
					],
					phone: [{
							required: true,
							message: '请输入联系电话',
							trigger: 'blur'
						}
					],
					area: [{
							required: true,
							message: '请输入工作地区',
							trigger: 'blur'
						}
					],
					address: [{
							required: true,
							message: '请输入详细地址',
							trigger: 'blur'
						}
					],
				},
				xzdyList: [{
						title: '不限'
					},
					{
						title: '30-100/天'
					},
					{
						title: '100-200/天'
					},
					{
						title: '200-300/天'
					},
					{
						title: '300-400/天'
					},
					{
						title: '400-500/天'
					},
					{
						title: '1000-2000/天'
					}
				],
				jsxsList: [{
						title: '日结'
					},
					{
						title: '周结'
					},
					{
						title: '月结'
					},
					{
						title: '完工结'
					}
				],
				ToppingData:[],
				zwflList:[],
				loading:false
			}
		},
		watch: {
			visible: {
				handler(val, older) {
					console.log(val)
					if(val==true) {
						this.getToppingList()
						this.getpositionType()
					}
				}
			}
		},
		mounted() {
		},
		methods: {
			getpositionType(){
				positionType().then(res=>{
					this.zwflList = res.data
				})
			},
			getToppingList(){
				ToppingList().then(res=>{
					this.ToppingData = res.data
				})
			},
			close() {
				this.$emit('update:visible', false);
				this.$refs.editForm.resetFields();
			},
			confirm() {
				console.log(this.editForm)
				var _this = this
				this.$refs.editForm.validate((valid) => {
				          if (valid) {
							  this.loading = true
				            if (this.editForm.id) {
				            	console.log('编辑')
								positionEdit(this.editForm).then(res=>{
									this.$message({
										message: res.message,
										type: 'success',
										onClose(e) {
											_this.loading = false
											_this.$emit('update:visible', false);
											_this.$emit('confirm');
										}
									})
								})
				            } else {
				            	console.log('新增')
								positionAdd(this.editForm).then(res=>{
									this.$message({
										message: res.message,
										type: 'success',
										onClose(e) {
											_this.loading = false
											_this.$emit('update:visible', false);
											_this.$emit('confirm');
										}
									})
									
									
								})
				            }
				          } else {
				            console.log('error submit!!');
				            return false;
				          }
				        });
						
				
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	// ::-webkit-scrollbar {
	
	//   display: none; /* Chrome Safari */
	
	// }
</style>