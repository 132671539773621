import request from '@/utils/request'

// 获取置顶套餐
export function ToppingList(data) {
  return request({
    url: '/pcharge/ToppingList',
    method: 'get',
	params:data
  })
}
// 编辑置顶套餐
export function ToppingEdit(data) {
  return request({
    url: '/pcharge/ToppingEdit',
    method: 'post',
	data:data
  })
}
// 新增置顶套餐
export function ToppingAdd(data) {
  return request({
    url: '/pcharge/ToppingAdd',
    method: 'post',
	data:data
  })
}
// 删除置顶套餐
export function ToppingDelete(data) {
  return request({
    url: '/pcharge/ToppingDelete',
    method: 'post',
	data:data
  })
}

