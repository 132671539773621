<template>
	<div>
		<el-dialog title="提示" :visible.sync="visible" width="40%">
			<el-form :model="editForm" :rules="rules" ref="editForm" label-width="100px" class="demo-editForm">
				<div style="max-height: 500px;overflow-x: auto;">
				<el-form-item label="用户名" prop="username">
					<el-input v-model="editForm.username"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password" v-if="isEdit==false">
					 <el-input v-model="editForm.password"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					 <el-input v-model="editForm.phone"></el-input>
				</el-form-item>
				<el-form-item label="真实姓名" prop="fullName">
					 <el-input v-model="editForm.fullName"></el-input>
				</el-form-item>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" @click="confirm" :loading="loading">提 交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	
	import {
		addUser,
		EditUser
	} from '@/api/user'
	export default {
		name: 'editdPositionDialogVisible',
		props: {
			editForm: Object,
			visible: Boolean
		},
		data() {
			return {
				rules: {
					username: [{
							required: true,
							message: '请输入用户名',
							trigger: 'blur'
						}
					],
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						}
					],
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						}
					],
					fullName: [{
							required: true,
							message: '请输入真实姓名',
							trigger: 'blur'
						}
					]
				},
				xzdyList: [{
						title: '不限'
					},
					{
						title: '30-100/天'
					},
					{
						title: '100-200/天'
					},
					{
						title: '200-300/天'
					},
					{
						title: '300-400/天'
					},
					{
						title: '400-500/天'
					},
					{
						title: '1000-2000/天'
					}
				],
				jsxsList: [{
						title: '日结'
					},
					{
						title: '周结'
					},
					{
						title: '月结'
					},
					{
						title: '完工结'
					}
				],
				ToppingData:[],
				zwflList:[],
				loading:false,
				isEdit:false
			}
		},
		watch: {
			visible: {
				handler(val, older) {
					if (this.editForm.id) {
						console.log('编辑')
						this.isEdit = true
					} else {
						this.isEdit = false
					}
				}
			}
		},
		mounted() {
		},
		methods: {
			close() {
				this.$emit('update:visible', false);
				this.$refs.editForm.resetFields();
			},
			confirm() {
				console.log(this.editForm)
				var _this = this
				this.$refs.editForm.validate((valid) => {
				          if (valid) {
							  this.loading = true
				            if (this.editForm.id) {
				            	console.log('编辑')
								EditUser(this.editForm).then(res=>{
									this.$message({
										message: res.message,
										type: 'success',
										onClose(e) {
											_this.loading = false
											_this.$emit('update:visible', false);
											_this.$emit('confirm');
										}
									})
								})
				            } else {
				            	console.log('新增')
								this.editForm.isAdmin = 1
								addUser(this.editForm).then(res=>{
									this.$message({
										message: res.message,
										type: 'success',
										onClose(e) {
											_this.loading = false
											_this.$emit('update:visible', false);
											_this.$emit('confirm');
										}
									})
									
									
								})
				            }
				          } else {
				            console.log('error submit!!');
				            return false;
				          }
				        });
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	// ::-webkit-scrollbar {
	
	//   display: none; /* Chrome Safari */
	
	// }
</style>