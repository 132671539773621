<template>
	<div>
		<!-- 搜索区域 -->
<!-- 		<el-card class="box-card">
			<el-form :model="queryParam" :rules="rules" ref="form" label-width="80px">
				<el-row :gutter="12">
					<el-col :span="5">
						<el-form-item label="关键字" prop="keyword">
							<el-input v-model="queryParam.keyword" placeholder="关键字"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item>
							<el-button type="primary" @click="submitForm">查询</el-button>
							<el-button @click="resetForm">重置</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-card> -->
		<!-- 操作区 -->
		<el-card class="box-card" style="text-align: right;">
			<el-button type="primary" @click="editRow({})">添加</el-button>
		</el-card>
		<!-- 内容区 -->
		<el-card class="box-card">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="title" label="套餐名称">
				</el-table-column>
				<el-table-column prop="price" label="价格">
				</el-table-column>
				<el-table-column prop="day" label="置顶时长/天">
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="200">
					<template slot-scope="scope">
						<el-button @click="editRow(scope.row)" size="small">编辑</el-button>
						<el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteRow(scope.row)">
							<el-button slot="reference" size="small">删除</el-button>
						</el-popconfirm>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
		<editdToppingDialogVisible :editForm="editForm" :visible.sync="editdialogVisible" @confirm="confirmeditForm" @close="closeeditForm"></editdToppingDialogVisible>
	</div>
</template>

<script>
	import {
		ToppingList,
		ToppingDelete
	} from '@/api/commodity'
	import editdToppingDialogVisible from '@/components/editdToppingDialogVisible/index.vue'
	export default {
		components:{
			editdToppingDialogVisible
		},
		data() {
			return {
				queryParam: {
					keyword: '',
				},
				rules: {
					keyword: [{
						required: false,
						trigger: 'change'
					}],
					class: [{
						required: false,
						trigger: 'change'
					}],
					method: [{
						required: false,
						trigger: 'change'
					}],
				},
				positionTypeList: [],
				tableData: [],
				editForm:{},
				editdialogVisible:false
			}
		},
		mounted() {
			this.getToppingList()
		},
		methods: {
			confirmeditForm(e){
				console.log(e)
				this.getToppingList()
			},
			closeeditForm(e){
				console.log(e)
			},
			handleSizeChange(val) {
				this.queryParam.size = val
				this.getToppingList()
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.queryParam.page = val
				this.getToppingList()
				console.log(`当前页: ${val}`);
			},
			editRow(item) {
				this.editForm = item
				this.editdialogVisible = true
			},
			deleteRow(item) {
				var data = {
					id:item.id,
				}
				ToppingDelete(data).then(res=>{
					var _this = this
					this.$message({
						message: res.message,
						type: 'success',
						onClose(e) {
							_this.getToppingList()
						}
					})
				})
			},
			examineRow(item) {
				var data = {
					id: item.id
				}
				if (item.status == 1) {
					data.status = 2
					console.log('审核驳回')
				} else {
					data.status = 1
					console.log('审核通过')
				}
				positionExamine(data).then(res => {
					var _this = this
					this.$message({
						message: res.message,
						type: 'success',
						onClose(e) {
							_this.getToppingList()
						}
					})

					console.log(res)
				})
			},
			getToppingList() {
				ToppingList(this.queryParam).then(res => {
					this.tableData = res.data
				})
			},
			submitForm() {
				this.getToppingList()
			},
			resetForm() {
				this.$refs.form.resetFields();
			},
		}
	}
</script>

<style lang="scss" scoped>
	.box-card {
		margin-bottom: 12px;
	}

	.bg-purple {
		background: #d3dce6;
	}

	.el-form-item {
		margin-bottom: 0;
	}
	.paginationBox{
		margin-top: 24px;
		text-align: center;
	}
</style>