<template>
	<div>
		<el-dialog title="提示" :visible.sync="visible" width="40%">
			<el-form :model="editForm" :rules="rules" ref="editForm" label-width="100px" class="demo-editForm">
				<div style="max-height: 500px;overflow-x: auto;">
					<el-form-item label="商品名称" prop="title">
						<el-input v-model="editForm.title"></el-input>
					</el-form-item>
					<el-form-item label="价格" prop="price">
						<el-input v-model="editForm.price"></el-input>
					</el-form-item>
					<el-form-item label="置顶时长/天" prop="day">
						<el-input v-model="editForm.day"></el-input>
					</el-form-item>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" @click="confirm" :loading="loading">提 交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ToppingList,
		ToppingEdit,
		ToppingAdd
	} from '@/api/commodity'
	export default {
		name: 'editdPositionDialogVisible',
		props: {
			editForm: Object,
			visible: Boolean
		},
		data() {
			return {
				rules: {
					title: [{
						required: true,
						message: '请输入商品名称',
						trigger: 'blur'
					}],
					price: [{
						required: true,
						message: '请输入商品名称',
						trigger: 'blur'
					}],
					day: [{
						required: true,
						message: '请输入置顶天数',
						trigger: 'blur'
					}],
				},
				loading: false
			}
		},
		watch: {},
		mounted() {},
		methods: {
			close() {
				this.$emit('update:visible', false);
				this.$refs.editForm.resetFields();
			},
			confirm() {
				console.log(this.editForm)
				var _this = this
				this.$refs.editForm.validate((valid) => {
					if (valid) {
						this.loading = true
						if (this.editForm.id) {
							console.log('编辑')
							ToppingEdit(this.editForm).then(res => {
								this.$message({
									message: res.message,
									type: 'success',
									onClose(e) {
										_this.loading = false
										_this.$emit('update:visible', false);
										_this.$emit('confirm');
									}
								})
							})
						} else {
							console.log('新增')
							ToppingAdd(this.editForm).then(res => {
								this.$message({
									message: res.message,
									type: 'success',
									onClose(e) {
										_this.loading = false
										_this.$emit('update:visible', false);
										_this.$emit('confirm');
									}
								})


							})
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	// ::-webkit-scrollbar {

	//   display: none; /* Chrome Safari */

	// }
</style>