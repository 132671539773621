import request from '@/utils/request'

// 获取用户信息
export function userInfo(data) {
  return request({
    url: '/user/getuserInfo',
    method: 'get',
	params:data
  })
}

// 获取管理员列表
export function adminList(data) {
  return request({
    url: '/user/adminList',
    method: 'get',
	params:data
  })
}

// 获取用户列表
export function userList(data) {
  return request({
    url: '/user/userList',
    method: 'get',
	params:data
  })
}

// 根据id删除用户
export function deleteUser(data) {
  return request({
    url: '/user/delete',
    method: 'post',
	data:data
  })
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/user/addUser',
    method: 'post',
	data:data
  })
}

// 根据id编辑用户信息
export function EditUser(data) {
  return request({
    url: '/user/EditUser',
    method: 'post',
	data:data
  })
}

