<template>
	<div>
		<!-- 搜索区域 -->
		<el-card class="box-card">
			<el-form :model="queryParam" :rules="rules" ref="form" label-width="80px">
				<el-row :gutter="12">
					<el-col :span="5">
						<el-form-item label="用户名" prop="username">
							<el-input v-model="queryParam.username" placeholder="用户名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="昵称" prop="nickname">
							<el-input v-model="queryParam.nickname" placeholder="昵称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="真实姓名" prop="fullName">
							<el-input v-model="queryParam.fullName" placeholder="真实姓名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item>
							<el-button type="primary" @click="submitForm">查询</el-button>
							<el-button @click="resetForm">重置</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<!-- 操作区 -->
		<!-- <el-card class="box-card" style="text-align: right;">
			<el-button type="primary" @click="editRow({})">添加</el-button>
		</el-card> -->
		<!-- 内容区 -->
		<el-card class="box-card">
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column prop="username" label="用户名">
				</el-table-column>
				<el-table-column prop="nickname" label="昵称">
				</el-table-column>
				<el-table-column label="头像">
					<template slot-scope="scope">
						<el-avatar shape="square" size="large" :src="scope.row.headimgurl"></el-avatar>
					</template>
				</el-table-column>
				<el-table-column prop="c_time" label="创建时间">
				</el-table-column>
				<el-table-column prop="phone" label="手机号">
				</el-table-column>
				<el-table-column prop="accountType" label="账号类型">
				</el-table-column>
				<el-table-column label="是否认证">
					<template slot-scope="scope">
						<span v-if="scope.row.fullName==null&&scope.row.phone==null">未认证</span>
						<span v-else>已认证</span>
					</template>
				</el-table-column>
				<el-table-column prop="fullName" label="真实姓名">
				</el-table-column>
				<el-table-column prop="enterpriseName" label="公司名称">
				</el-table-column>
				
				<el-table-column fixed="right" label="操作" width="240">
					<template slot-scope="scope">
						<!-- <el-button @click="editRow(scope.row)" size="small">编辑</el-button> -->
						<el-popconfirm title="确定删除吗？" @confirm="deleteRow(scope.row)">
							<el-button slot="reference" size="small">删除</el-button>
						</el-popconfirm>
					</template>
				</el-table-column>
			</el-table>
			<div class="paginationBox">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="queryParam.page" :page-sizes="[10, 20, 30, 40]" :page-size="queryParam.size"
					layout="total, sizes, prev, pager, next, jumper" :total="queryParam.total">
				</el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		userList,
		deleteUser
	} from '@/api/user'
	export default {
		data() {
			return {
				queryParam: {
					page: 1,
					size: 10,
				},
				rules: {
					keyword: [{
						required: false,
						trigger: 'change'
					}],
					class: [{
						required: false,
						trigger: 'change'
					}],
					method: [{
						required: false,
						trigger: 'change'
					}],
				},
				tableData: [],
				editForm:{},
				editdialogVisible:false
			}
		},
		mounted() {
			this.getuserList()
		},
		methods: {
			confirmeditForm(e){
				console.log(e)
				this.getuserList()
			},
			closeeditForm(e){
				console.log(e)
			},
			handleSizeChange(val) {
				this.queryParam.size = val
				this.getuserList()
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.queryParam.page = val
				this.getuserList()
				console.log(`当前页: ${val}`);
			},
			editRow(item) {
				this.editForm = item
				this.editdialogVisible = true
			},
			deleteRow(item) {
				var data = {
					id:item.id,
				}
				deleteUser(data).then(res=>{
					var _this = this
					this.$message({
						message: res.message,
						type: 'success',
						onClose(e) {
							_this.getuserList()
						}
					})
				})
			},
			getuserList() {
				userList(this.queryParam).then(res => {
					this.tableData = res.data.data
					this.queryParam.page = res.data.page
					this.queryParam.total = res.data.total
				})
			},
			submitForm() {
				this.getuserList()
			},
			resetForm() {
				this.$refs.form.resetFields();
			},
		}
	}
</script>

<style lang="scss" scoped>
	.box-card {
		margin-bottom: 12px;
	}

	.bg-purple {
		background: #d3dce6;
	}

	.el-form-item {
		margin-bottom: 0;
	}
	.paginationBox{
		margin-top: 24px;
		text-align: center;
	}
</style>