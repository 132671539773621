<template>
	<el-container class="home-container">
		<!--页面左侧边栏-->
		<el-aside :width="isCollapse ? '64px' : '250px'">
			<div>
				<span v-if="isCollapse===true">
					<img width="18px" height="18px" src="../assets/logo.png">
				</span>
				<span v-else-if="isCollapse===false">晋州零工市场管理系统</span>
			</div>

			<el-menu background-color="#1e7787" text-color="#fff" active-text-color="#C8C8C8" unique-opened
				:collapse="isCollapse" :collapse-transition="false" router :default-active="activePath">
				<el-menu-item index="/welcome">
				        <i class="el-icon-menu"></i>
				        <span slot="title">主页</span>
				      </el-menu-item>
				<el-submenu index="1">
					<template slot="title">
						<i class="el-icon-location"></i>
						<span>用户管理</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/userList">用户列表</el-menu-item>
					</el-menu-item-group>
					<el-menu-item-group>
						<el-menu-item index="/adminList">管理员列表</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
				<el-submenu index="2">
					<template slot="title">
						<i class="el-icon-location"></i>
						<span>岗位管理</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/positionList">岗位列表</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
				<el-submenu index="3">
					<template slot="title">
						<i class="el-icon-location"></i>
						<span>商品管理</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/commodityList">岗位置顶套餐</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
			</el-menu>
		</el-aside>
		<el-container>
			<!--页面头部-->
			<el-header>
				<el-row style="width:100%;">
					<el-col :span="12">
						<div class="grid-content bg-purple">
							<el-link @click="toggleCollpse" :underline="false" style="font-size: 24px;">
								<!-- isCollapse=true为收缩状态，false为展开状态 -->
								<i v-if="isCollapse===false" class="el-icon-s-fold"></i>
								<i v-else-if="isCollapse===true" class="el-icon-s-unfold"></i>
							</el-link>
						</div>
					</el-col>
					<el-col :span="12">
						<div style="float: right;">
							<span class="el-dropdown-link">
								{{usrsInfo.username}},你好
							</span>
							<span style="margin-left: 20px;">
								<el-button type="info" @click="logout" size="mini">退出</el-button>
							</span>
						</div>
					</el-col>
				</el-row>
			</el-header>
			<!--页面主体-->
			<el-main>
				<!--路由占位符-->
				<router-view></router-view>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import {
		userInfo
	} from '@/api/user'

	export default {
		data() {
			return {
				menulist: [],
				iconsObj: {
					125: 'el-icon-user-solid',
					103: 'el-icon-s-grid',
					101: 'el-icon-s-goods',
					102: 'el-icon-s-order',
					145: 'el-icon-s-marketing'
				},
				isCollapse: false,
				activePath: '',
				usrsInfo: {}
			}
		},
		mounted() {
			console.log(this.store)
			this.getuserInfo()
		},
		methods: {
			getuserInfo() {
				userInfo().then(res => {
					if (res.code != 200) {
						return this.$message.error(res.message)
					}
					this.usrsInfo = res.data
				})
			},
			logout() {
				window.sessionStorage.clear()
				this.$router.push('/login')
			},
			toggleCollpse() {
				this.isCollapse = !this.isCollapse
				console.log(this.isCollapse)
			},
			savaNavState(activePath, username) {
				window.sessionStorage.setItem('activePath', activePath)
				this.activePath = activePath
			},
		}
	}
</script>

<style lang="less">
	.home-container {
		width: 100%;
		height: 100%;
	}

	.el-header {
		background-color: #e4dbd4;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.el-aside {
		background-color: #1e7787;
		color: #ffffff;
		font-size: 20px;

		.el-menu {
			border-right: none;
		}

		>div {
			display: flex;
			height: 60px;
			align-items: center;
			justify-content: center;
		}

		span {
			// margin-left: 25px;
		}
	}

	.el-main {
		background-color: #f7f7f7;
	}
</style>