import request from '@/utils/request'

// 获取岗位分类
export function positionType(data) {
  return request({
    url: '/position/positionType',
    method: 'get',
	params:data
  })
}
// 获取岗位列表
export function positionList(data) {
  return request({
    url: '/position/query',
    method: 'get',
	params:data
  })
}
// 岗位审核
export function positionExamine(data) {
  return request({
    url: '/position/examine',
    method: 'post',
	data:data
  })
}
// 岗位删除
export function positionDelete(data) {
  return request({
    url: '/position/delete',
    method: 'post',
	data:data
  })
}
// 获取岗位置顶时长列表
export function ToppingList(data) {
  return request({
    url: '/pcharge/ToppingList',
    method: 'get',
	params:data
  })
}
// 岗位新增
export function positionAdd(data) {
  return request({
    url: '/position/add',
    method: 'post',
	data:data
  })
}
// 岗位编辑
export function positionEdit(data) {
  return request({
    url: '/position/edit',
    method: 'post',
	data:data
  })
}
